.container-fluid,
.container-left {
  display: flex;
  height: 100%;
}

.container-right {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.rce-container-mbox{
  margin-top: 1rem;
  width: 100% !important;
}
.bottom-info{
  width: 100%;
}