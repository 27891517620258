@keyframes enterIn {
    0% {
        transform: translate(-10rem -5rem);
    }

    100% {
        transform: translate(0rem 0rem);
    }
}

.shade {
    animation: enterIn 5s ease-in-out;
    position: absolute;
    display: flex;
    z-index: 10000;
    right: 0;
    height: 90%;
    width: 25%;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    top: 4rem;
    background-color: #fff;
    filter: drop-shadow(-.5rem 0.5rem 2rem #ddd)
}

.shade-header {
    margin: 1rem;
    font-weight: 700;
}