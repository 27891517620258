.notif-area {
    padding: 1rem;
    display: block;
}

.notif-area--bubble {
    position: absolute;
    transform: translate(.9rem, -.01rem) !important;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    background-color: var(--blue);
}