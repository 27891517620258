.video-feed {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.video-feed--feed {
    padding: 0rem 1rem 0rem 1rem;
    margin: 0rem -1rem 0rem -.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7e7e7;
    aspect-ratio: 2/3;
    height: 100%;
    width: 100%;
}

.video-feed--bar {
    flex: 1;
    height: 100%;
    width: 100%;
}

.video-feed--feed>video {
    width: 100% !important;
    height: auto !important;
    border-radius: 1%;
}